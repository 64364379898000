/*
    OverDrive Custom UI Tools
    Eric Tung
    2012 05 18
*/


// >> Generate Namespaces
if(!OD) var OD = {};
if(!OD.Tools) OD.Tools = {};



// >> Custom Plugins >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
// -- OverDrive Saved Search jQuery Plugin ------------------------------------
(function( $ ){

	// -- Empty Default Saved Search Template Object --------------------------
    // The model used for the data container MUST directly mirror OverDrive.ContentReserve.Core.Models.SavedSearch{}
    var oSavedSearchTemplate =
    {
        ID: "0",
        Name: "",
        Description: "",
        BookmarkFlag: false,
        
        SearchParameters:
        {
            ResultsPerPage:	"50",
            OrderBy:		"Relevancy",
            SortOrder:		"DESC",
            SearchFields:	{}
        }
    };


    // -- Internal Method Declaration -----------------------------------------
    function _Serialize(oParams)
    {
        var oData = {};

        // -- SearchParameters.SearchFields collection rules
        for(var i = 0; i < oParams.aoRules.length; i++)
        {
            var _rule = oParams.aoRules[i];
           
            if(typeof _rule.fSerialize == "undefined") // If there is no custom serialization rule execute standard element data collection
            {
                var $elm = $(_rule.sTarget, oParams.$context) || $([]);
                
                var aVals = [];
                $elm.each(function()
                {
                    var $this = $(this); // context
                    
                    if(($this.is(":checkbox") || $this.is(":radio")))
                    {	// If its a "checkable" only collect if "checked"
                        if($this.is(":checked")) aVals.push( $this.val() );
                    }
                    else
                    {	// else not relevant, just collect the value
                        if($this.val())
                            aVals.push( $this.val() );
                    }
                });

                // only add value to data stack if there is something to store
                if(aVals.length) oData[ _rule.sName ] = aVals;
            }
            else if(typeof _rule.fSerialize == "function") // There is a collection rule (lambda), utilize it
            {
                var args = (_rule.aSerializeParams || []);
                var _val = _rule.fSerialize.apply(this, args);
                if(_val.length) oData[ _rule.sName ] = _val;
            }
        }

        return oData;
    }


    function _Load(oParams, oData, bSuppresEvent)
    {
        bSuppresEvent = (bSuppresEvent || false);

        for(var i = 0; i < oParams.aoRules.length; i++)
        {
            var _rule = oParams.aoRules[i];

            if(typeof _rule.fDeserialize == "undefined")
            {
                var $elm = $(_rule.sTarget, oParams.$context) || $([]);

                if($elm.first().is(":checkbox") || $elm.first().is(":radio"))
                {
                    if(typeof oData[_rule.sName] != "undefined")
                    {
                        var aVals = oData[_rule.sName];
                        $elm.prop("checked", false);
                        $elm.each(function()
                        {
                            var $this = $(this);
                            if( $.inArray($this.val(), aVals) > -1 )
                            {
                                $this.prop("checked", true);
                                if(!bSuppresEvent) $this.trigger('change');
                            }
                        });
                    }
                }
                else
                {
                    if(typeof oData[_rule.sName] != "undefined" && oData[_rule.sName].length > 0) {
                        $elm.val( oData[_rule.sName][0] );
                        if(!bSuppresEvent) $elm.trigger('change');
                    }
                }
            }
            else if(typeof _rule.fDeserialize == "function")
            {
                if(typeof oData[_rule.sName] != "undefined")
                {
                    // Object deep copy nonsense to stop JavaScript from injecting the values into the aDeserializeParams via pass by reference
                    var temp = $.extend(true, {}, { aParams: (_rule.aDeserializeParams || []) });
                    var args = temp.aParams;

                    args.push(oData[_rule.sName]); // amend the incoming data to the parameter stack
                    _rule.fDeserialize.apply(this, args);
                }
            }
        }
    }


    function _Clear(oParams)
    {
        for(var i = 0; i < oParams.aoRules.length; i++)
        {
            var _rule = oParams.aoRules[i];

            if(_rule.bUnclearable) {
                continue;
            }

            if( typeof _rule.fDeserialize == "undefined" )
            {
                var $elm = $(_rule.sTarget, oParams.$context) || $([]);

                if($elm.first().is(":checkbox") || $elm.first().is(":radio"))
                {
                    $elm.prop("checked", false).trigger('change');
                }
                else if($elm.is("select"))
                {
                    $("option:first", $elm).prop("selected", true).trigger('change');
                }
                else
                {
                    $elm.val( "" ).trigger('change');
                }
            }
            else if(typeof _rule.fDeserialize == "function")
            {
                var args = (_rule.aDeserializeParams || []);
                args.push([]); // pass empty data stack
                _rule.fDeserialize.apply(this, args);
            }
        }
    }

    function _Delete( savedSearchID, fOnDelete )
    {
        if( confirm("Are you sure you would like to delete this saved search?") )
        {
            // >> Execute deleting the saved search
            $.ajax(
            {
                url: "/Search/DeleteSavedSearch",
                type: "post",
                data: AddAntiForgeryToken({
                        // "userid": oParams.iUserID, // not currently needed
                        "id": savedSearchID
                      }),
                success: function( sResponse )
                {
                    $('#savedSearchesContainer').trigger('updateSearches');
                    if( fOnDelete ) fOnDelete();
                },
                error: function(jqXHR, textStatus, errorThrown)
                {
                    alert("Sorry, but an error has occurred while deleting.\n" + errorThrown);
                }
            });
        }
    }

    function _ApplySearchParameters(oParams, oSavedSearchData)
    {
        // Load the SearchParameters that are not apart of SearchFields deserilization logic
        // This must happen prior to _Load to ensure these fields get populated with search criteria -- CRVTWO-1929
        $(":input.ResultsPerPage").val((oSavedSearchData.SearchParameters.ResultsPerPage || "")).trigger('change');
        $(":input.OrderBy").val((oSavedSearchData.SearchParameters.OrderBy || "")).trigger('change');
        $(":input.SortOrder").val((oSavedSearchData.SearchParameters.SortOrder || "")).trigger('change');

        _Load(oParams, oSavedSearchData.SearchParameters.SearchFields);
    };

    // -- External Plugin Methods ------------------------------------------------
    var methods = 
    {
        init: function( oParams )
        {
            return this.each(function()
            {
                // Assenble search params with user provided input
                oParams = $.extend(
                {
                    iUserID: 0,

                    // Target element (usualy the containing form or #element)
                    $context: $(this), // this should be implicitly resolved by the target element in $("target")

                    // Name / type'ish of the search taht is being created / saved
                    sSearchType: "", 

                    // Ruleset for [de]serilization of the target search instance
                    aoRules: [], // empty ruleset if one is not provided

                    // Object container for toggling functionality
                    oActiveDialogActions : {
                        "Save":		true,
                        "Cancel":	true,
                        "Delete":	false
                    }

                }, (oParams||{}));

                // Store the search config into the element's local data store
                $(this).data("oParams", oParams);
//				Data model should be as follows:
//				var = {
//					// The name of the type of the search that is being serialized
//					sSearchType: "Advanced",

//					// Parent object that contains all the child elements to be serialized
//					$Context: ".advSearch",

//					// An array of objects defining the serilizartion rules as a series of setions in the order of collection/population
//					aoRules: [
//						{
//							// The "name" that a field will be stored as in the SearchParameters.SearchFields["":{}, ... ] model
//							sName: "PublisherEntity",
//							// OPTIONAL: A custom serilization rule lambda (function) to perform complex data collection
//							fSerialize: fSerializeMultiselect,
//							// OPTIONAL: An array of values to pass to the custom serilization lambda
//							aSerializeParams: ["PublisherEntity"],
//							fDeserialize: fDeserializeMultiselect,
//							aDeserializeParams: ["PublisherEntity"]
//						},
//						...
//					]
//				}
            });
        },

        
        // Post init call to load a given saved search using the stored user provided configuration
        load: function( oLoadParams )
        {
            return this.each(function()
            {
                var $instance = $(this);

                // Setup defaults for making a data request
                oLoadParams = $.extend(
                {
                    id: 0,
                    success: function(){},
                    error: function(){}
                },
                (oLoadParams || {}));

                // Call in the Init(oParams)
                var oParams = $(this).data("oParams");

                // Assemble the initial data container
                var oSavedSearchData = $.extend({}, oSavedSearchTemplate);

                // >> Get Data
                $.ajax(
                {
                    url: "/Search/GetSavedSearch",
                    type: "post",
                    data: AddAntiForgeryToken({ "id": oLoadParams.id }),
                    success: function( sRawData )
                    {
                        var oData = {};
                        try{ oData = $.parseJSON( sRawData ) } catch(ex){ oData = {}; }

                        oSavedSearchData = $.extend(true, oSavedSearchData, oData);
                        $instance.data('oSavedSearchData', oSavedSearchData);

                        $.extend(true, {}, oSavedSearchData.SearchParameters.SearchFields);
                        
                        // Use private load method to deserialize data and repopulate target
                        _ApplySearchParameters(oParams, oSavedSearchData);

                        // Call optionally user provided lambda
                        oLoadParams.success( oSavedSearchData );
                    },
                    error: function(jqXHR, textStatus, errorThrown)
                    {
                        oLoadParams.error( jqXHR, textStatus, errorThrown );
                    }
                });
            });
        },
        
        loadData: function( oSavedSearchData, oLoadParams )
        {
            return this.each(function()
            {
                var $instance = $(this);

                // Setup defaults for making a data request
                oLoadParams = $.extend(
                {
                    complete: function(){}
                },
                (oLoadParams || {}));

                // Call in the Init(oParams)
                var oParams = $(this).data("oParams");

                // Assemble the initial data container
                oSavedSearchData = $.extend(true, oSavedSearchTemplate, oSavedSearchData);

                $.extend(true, {}, oSavedSearchData.SearchParameters.SearchFields);

                // Use private load method to deserialize data and repopulate target
                _ApplySearchParameters(oParams, oSavedSearchData);

                // Call optionally user provided lambda
                oLoadParams.complete( oSavedSearchData );
            });
        },

        // Method for saving a search
        save: function(searchDefinition)
        {
            return this.each(function()
            {
                var $instance = $(this);

                // Saved Search Params
                var oParams = $instance.data("oParams");

                // Load existing dataset if one exists otherwise generate a new instance
                var oSavedSearchData = $instance.data("oSavedSearchData");

                if (!oSavedSearchData) {
                    oSavedSearchData = $.extend({}, oSavedSearchTemplate);
                }
                
                // Create the dialog in memory for handling the user save action
                var $saveDialog = $(
                '<div id="modal" style="text-align: left;">'
                    + '<div class="modalContent">'
                            + '<div class="editor-field">'
                                + '<label for="Name"><span class="required">*</span>Name</label><br/>'
                                + '<input type="text" name="Name" id="Name" class="save-search-name-field" maxlength="20" />'
                                + '<p class="smallGrayTxt"><span class="field-validation-error" style="display:none; position: absolute;">Search cannot have a blank name.</span></p>'
                            + '</div>'
                            + '<div class="editor-field" style="margin-top: 16px;">'
                                + '<label for="Description">Description</label>'
                                + '<textarea name="Description" id="Description" style="height: 105px; width: 330px;"></textarea>'
                            + '</div>'
                            + '<div class="editor-field">'
                                + '<input type="checkbox" name="BookmarkFlag" id="BookmarkFlag" value="1" />'
                                + '<label style="margin-left: 5px;width: auto;" for="BookmarkFlag"> Pin search to menu</label>'
                        + '</div>'
                    + '</div>'
                + '</div>'
                );

                var $nameField = $("#Name", $saveDialog);
                $nameField.on('blur', function()
                {
                    var $this = $(this);
                    var $error = $(".field-validation-error", $this.parent());

                    $this.removeClass("input-validation-error");
                    $error.hide();
                    if( !$this.val().trim() )
                    {
                        $this.addClass("input-validation-error");
                        $error.show();
                    }
                });

                // Set the values from the data store in the save dialog
                $("#Name", $saveDialog).val( oSavedSearchData.Name );
                $("#Description", $saveDialog).val( oSavedSearchData.Description );
                $("#BookmarkFlag", $saveDialog).prop("checked", false);
                if( oSavedSearchData.BookmarkFlag ) $("#BookmarkFlag", $saveDialog).prop("checked", true);
                
                // Construct the buttons portion of the jQuery Dialog parameter object constructor
                var oDialogButtons = {};
                if(oParams.oActiveDialogActions["Save"]) {
                    $.extend(true, oDialogButtons, {
                        "Save": {
                            text: "Save",
                            "class": "button normalBtn save",
                            click: function() {
                                var $this = $(this);

                                var genericError = "Sorry, but an error has occurred while saving.";

                                // Collect the user input on the save dialog fields
                                var name = $("#Name", $this).val().trim();
                                var description = $("#Description", $this).val().trim();
                                var pinned = ($("#BookmarkFlag", $this).is(":checked") ? true : false);
                            
                                if(name !== "")
                                {
                                    // >> Execute storing the saved search
                                    if (oParams.fOnSaveStart) {
                                        oParams.fOnSaveStart();
                                    }

                                    $.ajax(
                                    {
                                        url: "/Shop/" + oSavedSearchData["MarketplaceSection"] + "/AdvancedSearch/SaveSearch",
                                        type: "POST",
                                        data: AddAntiForgeryToken({
                                            "q": encodeURIComponent(JSON.stringify(searchDefinition)),
                                            name: name,
                                            description: description,
                                            pinned: pinned
                                        }),
                                        success: function (response) {
                                            $(document).trigger('ajax-stop');

                                            if (response.success) {
                                                oSavedSearchData.ID = String(response.savedSearchId);
                                                oSavedSearchData.Name = name;
                                                oSavedSearchData.Description = description;
                                                oSavedSearchData.BookmarkFlag = pinned;

                                                $instance.data("oSavedSearchData", oSavedSearchData);
                                                // update the saved searches in the advanced search panel
                                                $('#SavedSearchesContainer').trigger('RefreshSavedSearchesList');
                                            } else {
                                                var message = searchDefinition.errorMessage || genericError;

                                                ShowToast({
                                                    Type: 2,
                                                    Message: message,
                                                    Location: 3,
                                                    Duration: 10000
                                                });
                                            }

                                            // -- Exec OnSave event handler if the user provided method
                                            if (oParams.fOnSave) {
                                                oParams.fOnSave(oData);
                                            }
                                        },
                                        error: function(jqXHR, textStatus, errorThrown)
                                        {
                                            ShowToast({
                                                Type: 2,
                                                Message: genericError,
                                                Location: 3,
                                                Duration: 10000
                                            });
                                        },
                                        complete: function(jqXHR, textStatus)
                                        {
                                            if (oParams.fOnSaveComplete) {
                                                oParams.fOnSaveComplete(jqXHR, textStatus);
                                            }
                                        }
                                    });

                                    // Done with the dialog
                                    $this.dialog('close');
                                }
                                else
                                {
                                    // Trigger the blur event to enforce display of required field error
                                    $nameField.trigger('blur');
                                }
                            
                            }
                        }
                    });
                }

                if(oParams.oActiveDialogActions["Cancel"]) {
                    $.extend(true, oDialogButtons, {
                        "Cancel":{
                            text: "Cancel",
                            "class": "button normalBtn",
                            click: function()
                            {
                                $(this).dialog('close');
                            }
                        }
                    });
                }

                if(oParams.oActiveDialogActions["Delete"]) {
                    $.extend(true, oDialogButtons, {
                        "Delete": {
                            text: "Delete",
                            "class": "button normalBtn",
                            click: function()
                            {
                                var fOnDelete = (oParams.fOnDelete || (function(){}));
                                _Delete( oSavedSearchData.ID, fOnDelete );
                            }
                        }
                    });
                }

                // Fix for dialog becoming unusable when its zIndex goes too high (values higher than 1000 breaks jQUI: http://bugs.jqueryui.com/ticket/5466)
                $.ui.dialog.maxZ = 1000;

                // Create base options object
                var oDialogOptions = {
                    modal: true,
                    width: 365,
                    resizable: false,
                    buttons: oDialogButtons,

                    // -- jQ Dialog Events --
                    open: function() {
                        // Select the search name field by default
                        $(this).find('#Name').focus();

                        $(this).parent().find('.ui-dialog-title').append('<h1>Save search</h1>');
                        $(this).parent().find('.ui-dialog-buttonset').addClass('right');

                        var $buttonDelete = $saveDialog.parent().find("button:contains('Delete')");
                        if (oSavedSearchData.ID == 0) $buttonDelete.hide();
                        else $buttonDelete.show();

                        // Clear the required field errors on the name field on dialog display
                        $nameField.removeClass("input-validation-error");
                        $(".field-validation-error", $nameField.parent()).hide();

                        // If we are inside an iframe do some acrobatics to get the "centered" absolute top position within the iframe
                        try {
                            if (window.self !== window.top) {
                                var iExternalOffset = (top.window.$("iframe:first").offset() || { top: 0, left: 0 })["top"];
                                var iParentHeight = $(top.window).height();

                                // Set the messages by determining the difference between the immediate parent container and the top visible window display area
                                iPosY = (iParentHeight / 2)
                                    + top.window.$(window).scrollTop()
                                        - ($(this).height() / 2)
                                            - iExternalOffset;

                                // Keep it from "smooshing" off the top of the page
                                iPosY = (iPosY < 10 ? 10 : iPosY);

                                var oPos = $(this).offset();
                                $(this).dialog("option", "position", [oPos.left, iPosY]);
                            }
                        } catch(ex) {
                        }

                    },
                    close: function() {
                        // Clean up after the dialog is done being used
                        $(this).dialog('destroy');
                        $(this).remove();
                    }
                };

                // Execute the dialog
                $saveDialog.dialog(oDialogOptions);
            });
        },


        // Retrieves the target as a JSON serialized SavedSearch
        get: function()
        {
            var $instance = $(this).first();

            // Saved Search Params
            var oParams = $instance.data("oParams");
            var oSearchFormData = _Serialize( oParams );


            // Load existing dataset if one exists otherwise generate a new instance
            var oSavedSearchData = $instance.data("oSavedSearchData");
            if(!oSavedSearchData) oSavedSearchData = $.extend({}, oSavedSearchTemplate);

            // Update the search with what the user has selected
            if(!oSavedSearchData.SearchParameters) oSavedSearchData.SearchParameters = $.extend({}, oSavedSearchTemplate.SearchParameters);
            oSavedSearchData.SearchParameters.SearchFields = oSearchFormData;

            // Only assign inbound values if they are not blank / null 
            oSavedSearchData.SearchParameters.ResultsPerPage = ($(":input.ResultsPerPage", $instance).val() || oSavedSearchData.SearchParameters.ResultsPerPage);
            oSavedSearchData.SearchParameters.OrderBy = ($(":input.OrderBy", $instance).val() || oSavedSearchData.SearchParameters.OrderBy);
            oSavedSearchData.SearchParameters.SortOrder = ($(":input.SortOrder", $instance).val() || oSavedSearchData.SearchParameters.SortOrder);

            return oSavedSearchData;
        },

        // Resets target saved search form
        // NOTE: Does not alter data like the SavedSearch name or description
        clear: function()
        {
            return this.each(function()
            {
                var $instance = $(this);

                // Wipe out current data model.
                $instance.removeData('oSavedSearchData');

                // Saved Search Params
                var oParams = $instance.data("oParams");

                // Clear the entire form and reset it to a default state
                _Clear( oParams );
            });
        },

        "delete": function( savedSearchID )
        {
            _Delete( savedSearchID );
		}
	};

	// -- Bind the extension name to the jQuery namespace ------------------------
	$.fn.SavedSearch = function( method )
	{
		if( methods[method] )
        {
            return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
        }
        else if( typeof method === 'object' || !method )
        {
            return methods.init.apply( this, arguments );
        }
        else
        {
            $.error( 'Method ' +  method + ' does not exist on $.SavedSearch' );
        }
    };
})( $ );